$breakpoint-phone: 450px;
$theme-colors: (
  "primary": #257c34,
  "primary2": #32753d,
  "primary3": #b2f8be,
  "gray1": #f8f8f8,
  "gray2": #888,
  "gray3": #6a6a6a,
  "gray4": #444,
  "gray5": #333,
  "gray6": #222,
  "gray7": #111,
  "gray8": #000,
);
